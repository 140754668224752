/* The cont_ter */
.cont_ter {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: 20px;
  }
  
  /* Hide the browser's default checkbox */
  .cont_ter input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .check2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 2px solid #DCDCDC;
  }
  
  /* On mouse-over, add a grey background color */
  .cont_ter:hover input ~ .check2 {
   
    background-color: #fff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .cont_ter input:checked ~ .check2 {
    background-color: #01D35A;
  }
  
  /* Create the check2/indicator (hidden when not checked) */
  .check2:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the check2 when checked */
  .cont_ter input:checked ~ .check2:after {
    display: block;
  }
  
  /* Style the check2/indicator */
  .cont_ter .check2:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
.btm_bdr[type="text"]{border: 0;
    border-bottom: 2px solid #B2B2B2;
    outline: 0;width: 83px;margin-top: 11px;}
    
    .price_se {
        border: none;
        color: #B2B2B2;
        /* overflow: hidden; */
        position: relative;
        text-align: center;
        border-radius: 3px;
    }
    
    .slec_p {
         padding: 0px 0px 0px 90px !important;
        background-color: white;
        border: 0;
        outline: none;
        font-size: 22px;
        -webkit-appearance: none; /* for webkit browsers */
        -moz-appearance: none; /* for firefox */
        appearance: none; /* for modern browsers */
        border-radius: 60px !important;
        margin-bottom: 0px;
    margin-top: 7px;
    }
    .icon_p_m {
      position: relative;
      top: 9px;
  }
        .icon_p_m .fa-plus{color: #01D35A;font-size: 12px; position: relative;
            left: 27px;
            top: -36px;}
        .icon_p_m .fa-minus{color: #01D35A;font-size: 12px; position: relative;
            left: 17px;
            top: -26px;}
            .icon_p_m .fa-chevron-down{position: absolute;
              right: 18px;
              top: 20px;}
            .slect_box_mdl{background: #fff;box-shadow: 0px 8px 12px #00000029;border-radius: 20px;border: 1px solid #DCDCDC;}
            .slect_box_mdl li {
              padding: 11px 0px 10px 0px;
          }



        /* modal */
        .set_u_t{font-size: 25px;margin-bottom: 40px;}
        .time_s h2{font-size: 28px;font-weight: 700;}
        .time_s small{font-size: 18px;margin-left: 9px;}
        ul.tme_d1{padding-left: 0;margin-right: 80px;}
        ul.tme_d{padding-left: 0;margin-left: 10px;}
        .new_scrol{display: flex;text-align: center;margin: 0 auto;width: 100px;margin-top: 20px; color:#ddd;
          font-size: 23px; font-weight: 400;}
          .active{color:#000 !important;}
          .new_scrol li{padding-bottom: 10px;}
          .new_scr2{width: 340px;
            height: 210px;
            overflow: auto;}