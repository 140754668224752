/* @font-face {
    font-family:'proxima';
    src:url(../../proxima.otf);
    font-style: "normal";
    font-weight: 500;
  } */
@media(max-width: 576px){

.loginImage
      {width:325px !important;}

.inputLogin
      {width: 100% !important;}

.form-controlll
      {width: 100% !important ;}
}
  
.box1{
      padding:10px 0px 10px 35px;
      width: 100%;
      height: auto;
      overflow: hidden;
      }

._app{
      background-color: #41cb52;
     }  
  
.nav1 
   {list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;}
  
.nav2 {
    float: right;}
  
.nav2 .nav3 {
    display: block;
    text-align: center;
    padding: 3px 18px;
    color: #707070 !important;
    text-decoration: none;
    border: 1px solid #707070;
    border-radius: 40px;
    margin-left: 20px;
    font-size: 14px;
    height: 31px;
    padding-right: 18px !important;
    padding-left: 18px !important;}
  
.nav33 
   {display: block;
    text-align: center;
    padding: 5px ;
    color:white !important;
    text-decoration: none;
    border: 1px solid white;
    border-radius: 20px;
    margin-left: 20px;
    font-size: 14px;
    width: 197px;
    height: 34px;}

.nav333
    {position: relative;
    bottom: 2px;}

@media (min-width: 992px)
  {
   .navbar-expand-lg .navbar-nav .nav-link 
        {padding-right: 1.5rem;
          padding-left: 1.5rem;}
  }
@media (max-width: 767px)
  {
    .nav2 
        {float: right;
        margin-bottom: 15px;}
  }

.nav2 .nav3:hover 
    {background-color: #01de5a;
    color: #fff;
    border-color: #01de5a;}

.button-login
    {margin-top: 21px;
      width: 270px;
      text-transform: none;
      height: 38px;
      border-radius: 44px !important;
      background-color: #01de5a;
      border: thin;
      color: white;
       outline: none !important;}

.login-text
      {font-size: 1.5rem;
      margin-top: 150px;}
    
.bg-light 
      {background-color: white !important;}

.inputLogin
      {border: none !important;
        border-radius: 0px !important;
        border-bottom: 1px solid #B2B2B2 !important;
        background-color: white !important;}

.forgotPassword
      {padding-left: 80px;}

.signupClass
      {font-size: 15px;
        padding-left: 33px;
        margin-top: 23px;}

.form-controlll
      {width: 120% ;
        outline: none;}

.loginImage
      {margin-top: 80px;}




/* sigun-up-header */

.sigunup_hedr{
      height: 70px;
    }
    .header {
      width: 100%;
  }
    .d_app{background: #01D35A;border-radius: 34px;color:#fff;padding: 7px 25px 7px 70px !important;}
    .ios_m {
        position: relative;
        top: -35px;
        left: 13px;
        width: 20px;
    }
    .andorid_m {
        position: relative;
        top: -34px;
        left: 14px;
        width: 20px;
    }
    .back_color_mo{margin-bottom: 8px;}
    .d_app:hover{color: #fff !important;}
    .sig_lgn{
          font-size: 13px;
          /* border:1px solid #01D35A; */
          /* color:#01D35A; */
          border: 1px solid #707070;
          color: #707070;
          border-radius: 54px;
          padding: 4px 29px 5px 32px !important;
          margin-left: 10px;
      }
    .signup_lgu{
          font-size: 13px;
          border: 1px solid #707070;color: #707070;border-radius: 54px;padding: 4px 25px 5px 28px !important;margin-left: 10px;}
    .sig_lgn:hover{color: #01D35A !important;}
    .lgo_sigun{
        width: 120px;
        padding-bottom: 19px;
        height: 60px;
    object-fit: contain;
    }

    .sig_lgn.active {
          color:#01D35A !important;
          border:1px solid #01D35A !important;
    }
    .botm_sign{padding-bottom: 7rem;}
    



    .login_flex_wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 0 0;
      height: inherit;
    }

    .login_flex_image_child {
      flex:1 1 45%;
      margin: 0 0;
      background-color: #F9F9F9;
      text-align: center;
    }

    .login_flex_form_child {
      flex:1 1 45%;
      margin: 0 0%;
    }

    .login_form_wrapper_rish {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 35rem;
      margin-top: 10rem;
    }