/* 
 @font-face {
    font-family: 'ProximaNova';
    src: local('ProximaNova'), url(../../proxima.otf) format('truetype');
  }
  
   */


.main_content_wrapper_rish {
    margin-top: 2rem;
    padding: 1rem;
}

.flex_child_three_rish {
    flex:1 1 25%;
    margin: 0 .5%;
}
.flex_child_seven_rish {
    flex:1 1 65%;
    margin: 0 .5%;
}
.flex_child_five_rish{
    flex:1 1 45%;
    margin: 0 .5%;
}

@media screen and  (max-width: 768px){
    .home_page_section1_secondary {
        text-align: center !important;
    }
    .home_page__sect2_heading_wrapper {
        text-align: center !important;
    }
 }
 
@media screen and  (max-width: 768px){
    .home_page_sec6_heading_wrap {
        margin-top: 3rem;
    }
 }




.home_page_section1_secondary {
    text-align: left;
    margin-top: 3rem;
}

 @media screen and  (max-width: 768px){
    .home_page_section3_secondary {
        text-align: center;
    }
 }

 .home_page_section1_secondary {
    text-align: left;
}



 .bold_text_rish {
     font-weight: bold !important;
 }


/* section 1 image csss */
.ai_offers_intelligent_solution {
    /* width: 70rem; */
    /* height: 30rem; */
} 
.image_class_rish {
    width:100%;
    object-fit:scale-down;
    object-position:50% 50%;
    max-height: 35rem;
}

@media (min-width: 620px) and (max-width: 768px) {
    .ai_offers_intelligent_solution {
       width: 40rem;
       /* height: ; */
    } 
 }
 /* section 3 */

 .home_page_section2_secondary{
        margin-top: auto;
        margin-bottom: auto;
 }  

 /* // secion 4 */
 #how_it_works_div {
    background: transparent url('../../images/blue_eclipsce.svg') center no-repeat;
    width: 100%;
    height: 80%;
    background-size: 100% 100%;
    position: relative;
    padding: 2rem;
 }

 .home_page_how_works_div {
    height: 40rem !important;
    position: relative;
 }

 .home_page_sec4_heading_wrap {
     height: 100%;
 }

 #why_plunes_home_div {
    background: transparent url('../../images/pink_circle.svg') center no-repeat;
    width: 100%;
    height: 80%;
    background-size: 100% 100%;
    position: relative;
    padding: 2rem;
 }

 .sect7_data_span{
     margin-left: 2rem;
     display: inline-flex;
     flex-direction: column;
     justify-content: center;
 }
 .icon_rish_sect7 {
    height: 3rem;
}

@media screen and  (max-width: 768px){
    .home_page_section3_secondary{
        margin-left: 0rem;
    }
}

.rish_flex_wrap_sect7 {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1%;
}
.rish_flex_wrap_sect7_child1 {
    flex:1 1 10%;
    max-width: 4rem;
    margin: 0 .5%;

    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}
.rish_flex_wrap_sect7_child2 {
    flex:1 1 80%;
    margin: 0 .5%;

    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}


.follow-us-at {
    display: inline-block;padding-left: 0;
}

.follow-us-at img {
    width: 2rem;
    height: 2rem;
    margin: .5rem;
    border-radius: 50%;
}
.follow-us-at{
    padding-inline-start: 0px;
    }

    .follow-us-at{
        display: inline-block;
      }
.services {
    padding-left: 0rem;
}

.iframe_wrapper_home {
    height: 90%;
    width: 90%;
    padding: 1;
}
.home_page_iframe {
    height: 70%;
    width: 100%;
}

.home_page_ul_rish {
    padding-left: 0rem;
}

.home_page_section_1_image {
    /* max-height: 50rem !important; */
    width: 100%;
    -o-object-fit: scale-down;
    object-fit: scale-down;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    max-height: 26rem;
}

.sub_heading_home_page_rish {
    line-height: 2.6rem;
}


.see_how_we_work_iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 20rem;
    width: 40rem;
  }
  .see_how_we_work_iframe_wrap {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }

  #how_we_do_section  {
      margin-top: 3rem;
  }

  @media screen and (max-width:767px) {

    .cryp-section {
        padding: 0;
    }
    .see_how_we_work_iframe {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 20rem;
        width: 40rem;
      }
      .see_how_we_work_iframe_wrap {
        width: 100%;
        padding: 1rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
      }
      #how_we_do_section  {
        margin-top: 1rem;
    }
  }


        .fullwidthvideoblock {
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
            
            display: none;
        }
        .fullwidthvideoblock2 {
            margin: auto;
            width: 50%;
            text-align: center;
        }
        @media (max-width: 1000px) {
            .fullwidthvideoblock2 {
                width: 80%;
            }
        }
        @media (max-width: 40px) {
            .fullwidthvideoblock2 {
                width: 100%;
            }
        }
            
        .fullwidthvideocontainer {
            position: relative;
            width: 100%;
            padding-bottom: 56.25%;
        }
        .fullwidthvideocontent {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

            .history-item__desc {
                font-size: 16px;
            }
    
    @media (max-width: 1000px) {
        .main_hiv_logo_repeat_block {
            display: none;
        }
    }

    .our_proccess_wrapper {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width:900px) {
        .our_proccess_wrapper {
            width: 80%;
        }
    }
      @media screen and (max-width:767px) {
        .our_proccess_wrapper {
            width: 70%;
        }
    }



    .how_we_do_slider_wrapper {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
      }
      
      .how_we_do_item_wrap {
        display: flex;
        flex-direction: row;
      }
      
      .how_we_do_item_child {
        flex: 1 1 45%;
        margin: 1%;
        display: flex;
        align-items: center;
      }
      
      .how_we_do_image{
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 15rem;
      }
      
      .how_we_do_child_2 {
        display: flex;
        flex-direction: column;
      }
      
      .how_we_do_child_2_icon {
        height: 8rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      .how_we_do_child_2_text {
        font-size: 1.5rem;
        font-family: "ProximaNova";
        margin-top: 2rem;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
      .how_we_do_child_2_head {
          font-size: 2rem !important;
      }

      .vertical_space_around {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	
	}