@font-face {
    font-family: alt_thin_proxima;
    src: url('../../Proxima Nova Alt Thin.otf');
}
@font-face {
    font-family: light_proxima;
    src: url('../../Proxima Nova Alt Light.otf');
}
@font-face {
    font-family: black_proxima;
    src: url('../../Proxima Nova Black.otf');
}
@font-face {
    font-family: bold_proxima;
    src: url('../../Proxima Nova Alt Bold.otf');
}
@font-face {
    font-family: extra_bold_proxima;
    src: url('../../Proxima Nova Extrabold.otf');
}
@font-face {
    font-family: regular_proxima;
    src: url('../../ProximaNova-Regular.otf');
}
@font-face {
    font-family: thin_proxima;
    src: url('../../Proxima Nova Thin.otf');
}


