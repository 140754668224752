@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }
    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }
    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInButton {
    0% {
        opacity: 0;
        transform:translateY(10rem);
    }
    100% {
        opacity: 1;
        transform:translateY(0px);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(-3rem);
    }
    80% {
        transform: translateY(-.3rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes bid_animation {
    0% {
        opacity: 0;
        transform: translateY(-2rem);
    }
    80% {
        transform: translateY(-.3rem);
    }

    100% {
        opacity: 1;
       
        transform: translate(0);
    }
}

@-webkit-keyframes pop-in {
0% { opacity: 0; -webkit-transform: scale(0.5); }
100% { opacity: 1; -webkit-transform: scale(1); }
}
@-moz-keyframes pop-in {
0% { opacity: 0; -moz-transform: scale(0.5); }
100% { opacity: 1; -moz-transform: scale(1); }
}
@keyframes pop-in {
0% { opacity: 0; transform: scale(0.5); }
100% { opacity: 1; transform: scale(1); }
}
