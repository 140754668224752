.ReactModal__Content--after-open{
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        box-shadow: 5px 5px 10px #00000029 !important;
        /* border-radius: 20px !important; */
}
.AvailableTime
        {background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DCDCDC;
        opacity: 1;  border-radius: 10px; }
.Avail
       {text-align: center;     
        font-size: 24px;  
        font-weight: bolder;}

.AvailTime
       {padding: 25px;}

form 
       {margin: 35px 15px 40px 15px;}

.Availrow
       {margin: 20px 0px 20px 0px;}

.day
       {text-align: center;
        width: 35px;
        height: 35px;
        background: #EBEBEB 0% 0% no-repeat padding-box;
        font-size: 15px;
        padding: 28px;
        border-radius: 50%;}

.Timerow
       {margin-bottom: 9px;}

.Availputtime
       {margin: 0px 25px 0px 25px;}

.AvailHeadrow
       {font-weight: 400;}

/* The container */

.contain 
        {display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;}

        .abt_sec{padding-top: 30px;font-size: 18px;}
        .my_av_sec{margin-bottom: 30px;}
        .my_avl{margin-bottom: 30px;}
          

  /* MANAGE PAYMENT CSS */
.ManagePayForm
    {background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DCDCDC;
    opacity: 1;
    letter-spacing: 0px;   
    border-radius: 12px;padding: 40px;}
.bok_apt{font-size: 1.2rem;font-weight: 600;
  position: relative;
  right: 25px;top: 6px;}
  .managePay {
    margin-top: 1rem !important;
  }
  

.editbankdetailfield {
    border: none !important;
    border-bottom: 1px solid #B5B3B3 !important;
    border-radius: 0px !important;
    margin: 1rem 0rem;
    font-size: 1rem !important;
}

.managePay
    {margin: 30px;}
    .managePay h4 b{font-size: 1.5rem !important;}
   

.proceedbtn 
    {background: #01D35A 0% 0% no-repeat padding-box;
    border-radius: 35px !important;
    opacity: 1;
    width: 26%;
    height: 47px;
    font-size: 17px !important;text-transform: capitalize !important;}

/* APPOINTMENTS */

.Appoint
    {background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DCDCDC;
    border-radius: 10px;
    } 

.AppointBody
     {margin: 25px;}

.AppointBodyrow1
     {text-align: center;      
      font-size: 18px;   
      font-weight: bolder;     
      margin-bottom: 0px;}

.AppointBodyrow2
     {margin-top: 10px;}

.Appointimg
      {width: 50px;
      height: 50px;
      background: #888888 0% 0% no-repeat padding-box;
      padding: 12px;
      text-align: center;
      border-radius: 50%;
      font-weight: initial;
      color: white;
      font-size: 18px;}

.Appointimgrow
     {text-align: -webkit-right;} 

.AppointNameBold
     {font-weight: 500;}

.AppointStatus
    {letter-spacing: 0;
    color: #01D35A;
    opacity: 1;
    font-weight: 500;} 

.appointBookId
      {color: darkgrey;}

    /* SETTING CSS */
.switch 
      {position: relative;
      display: inline-block;
      width: 52px;
      height: 30px;}

.switch input 
     {opacity: 0;
      width: 0;
      height: 0;}

.slider 
      {position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color:#DFDFDF;
      -webkit-transition: .4s;
      transition: .4s;}

.slider:before 
    {position: absolute;
      content: "";
      height: 32px;
      width: 32px;
      left: 0px;
      bottom: 0px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s; box-shadow: 1px 1px 1px 1px darkgrey;}

input:checked + .slider 
      {background-color: #01D35A;}

input:focus + .slider 
      {box-shadow: 0 0 1px #01D35A;}

input:checked + .slider:before 
      {-webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);}

.idea{
    display: inline-flex;
    border: none;
    background: transparent;
    color: #01D35A;
    font-weight: 400;
}
.ideaDiv{
    background-color: #FBFBFB;
    color: #01D35A;
    font-weight: 500;
    margin: 2%;
}
.tip{
    margin-top: 27px;
}
.tipsrow {
    margin: 1% 12% !important;
}
.appointCreateP{
    text-decoration: underline;
    letter-spacing: 0px;
    color: #01D35A;
    font-size: 18px;
    font-weight: bolder;
}
.cross{
    border: none;
    background-color: white;
}

/* Rounded sliders */

.slider.round 
      {border-radius: 34px;}

.slider.round:before 
     {border-radius: 50%;}

.settingpage
    {margin-top: 93px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #DCDCDC;
      border-radius: 10px;margin-left: 5rem;}

.settingpageBody
   {
    padding: 1rem;
   }

.accset 
    {border: 1px solid #DCDCDC;
    margin-top: 20px; margin-bottom: 20px;
    border-radius: 10px;}

.settingtopic p
    {text-align: center;
    font-size: 1.8rem;
    font-weight: bolder;} 

.accsetrow 
    {margin: 23px 0px 16px 0px !important;}

/* PAYMENT STATUS PROGRESS BAR */
.progres{
      height: 114px;
}
.progressbar{
  counter-reset: step;
}
.progressbar li{
  list-style-type:none;
  float: left;
  width:20%;
  position:relative;
  text-align:center;
}
.progressbar li:before{
  content: counter(step);
  counter-increment: step;
  width: 50px;
  height: 50px;
  border: 1px solid darkgrey;
  display: block;
  text-align:center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height:50px;
  background-color:white;
}
.progressbar li:after{
  content: '';
  position:absolute;
  width:100%;
  height:3px;
  background-color:#F1F1F1;
  top:25px;
  left:-50%;
  z-index:-1;
}
.progressbar li:first-child:after{
  content:none;
}
.progressbar li.active{
  color:#01D35A;
}
.progressbar li.active:before{
  border-color: #01D35A;
}

/* Progress */

/* .progres li{
  width:20%;
}
ul{
  text-align:center;
}
ul li{
  display: inline-block;
}
ul li .fa{
    background-color:#01D35A;
    color:white;
    width:50px;
    height:50px;
    border-radius:50%;
}
.fa-check:before {
    line-height: 50px;
} */

.ReactModal__Overlay{background-color: rgba(80, 77, 77, 0.21) !important;z-index: 11;}
.persent_md{padding: 40px !important;}