/* General button style */
.btn_rish {
	border: none;
	font-family: 'ProximaNova';
	font-size: inherit;
	color: inherit;
	background: none;
	cursor: pointer;
	padding: 1.8rem 4rem;
	display: inline-block;
	/* margin: 1rem 2rem; */
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.btn_rish:after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

/* Pseudo elements for icons */
.btn_rish:before {
	font-family: 'FontAwesome';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	position: relative;
	-webkit-font-smoothing: antialiased;
}


/* Icon separator */
.btn_rish-sep {
    padding: 1rem .5rem 1rem 4.5rem;
}

.btn_rish-sep:before {
	background: rgba(0,0,0,0.15);
}

/* Button 1 */
.btn_rish-1 {
	background: #3498db;
	color: #fff;
}

.btn_rish-1:hover {
	background: #2980b9;
}

.btn_rish-1:active {
	background: #2980b9;
	top: 2px;
}

.btn_rish-1:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 3;
	font-size: 140%;
	width: 4rem;
}

/* Button 2 */


.btn_rish-3 {
	background: red;
	color: #fff;
}

.btn_rish-3:hover {
	background: #27ae60;
}

.btn_rish-3:active {
	background: #27ae60;
	top: 2px;
}

.btn_rish-3:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 2.5;
	font-size: 140%;
	width: 4rem;
}
.btn_rish-2 {
	background: #2ecc71;
	color: #fff;
}
.btn_rish-2:hover {
	background: #27ae60;
}

.btn_rish-2:active {
	background: #27ae60;
	top: 2px;
}

.btn_rish-2:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 2.5;
	font-size: 140%;
	width: 4rem;
}

/* Button 3 */
.btn_rish-3 {
	background: #e74c3c;
	color: #fff;
}

.btn_rish-3:hover {
	background: #c0392b;
}

.btn_rish-3:active {
	background: #c0392b;
	top: 2px;
}

.btn_rish-3:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 3;
	font-size: 140%;
	width:4rem;
}

/* Button 3 */
.btn_rish-4 {
	background: #34495e;
	color: #fff;
}

.btn_rish-4:hover {
	background: #2c3e50;
}

.btn_rish-4:active {
	background: #2c3e50;
	top: 2px;
}

.btn_rish-4:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 3;
	font-size: 140%;
	width: 4rem;
}

/* Icons */

.icon-cart:before {
	content: "\f093";
}

.icon-delete:before {
	content: "\f014";
}

.icon-heart:before {
	content: "\f55a";
}

.icon-info:before {
	content: "\f05a";
}

.icon-send:before {
	content: "\f1d8";
}

.icon-download:before {
	content: "\f019";
}
.thin_button_text{
	font-weight: 100 !important;
	font-size: 1rem;
}

.no_icon_btn {
		padding: 1rem 2rem !important;
}