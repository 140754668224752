#root {
  height: 100vh;
}


.App {
  width: 90%;
  margin: auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }


  
}


html {
  font-size: 63.5%;
}
/* phone */
  @media screen and (max-width: 37.5em) {
    html {
      font-size: 42%;
     }
}

/* tab-port */
@media screen and ( max-width: 56.2em) {
  html {
    font-size: 50%;
   }
}

@media screen and (max-width: 112.5em) {
  html {
    font-size: 75%;
   }
}

 @media screen and (min-width: 112.5em) {
  /* .main-body-wrapper{
    margin-left: 35rem !important;
    margin-right: 35rem !important;
    margin-top: 2rem;
  }  */
  .sidenav{
    width: 100% !important;
  }
}

.fa, .far, .fas {
  font-family: "Font Awesome 5 Free" !important;
}




