.remove_speciality_text {
    font-size: 1rem;
    color: red;
    text-decoration: underline;
    cursor: pointer;
}

.address_field {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid gray;
    width: 30rem;
}

.green_text_rish{
    color: #01D35A;;
  }
  .icon_rish {
      height: 1rem;
  }

  .flex_wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .flex_wrap_child_3 {
    flex:1 1 25;
    margin: 0 1%;
  }
  .flex_wrap_child_7 {
    flex:1 1 65;
    margin: 0 1%;
  }

  .vertical_align_rish {
    display: block !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .profile_camera_rish{
    display: none;
    position: absolute;
    top: 2rem;
    left: 2.5rem;
  }
  .image_wrapper_add_doctor:hover  + .profile_camera_rish {
      display: inline;
  }
  .link_text_rish_medium {
    text-decoration: underline;
    font-weight: bold;
    color: #01D35A;
    font-size: 1.2rem;
    font-family:  "Proxima Nova";
  }

.page_min_height_rish {
    min-height: 30rem;
}
.align_center_rish {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.align_vertical_rish {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}
.display_flex_rish {
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
}

.flex_wrap_rish { 
      display: flex;
      flex-wrap: wrap;
      padding: 0 1%;
}

.flex_wrap_rish_column-reverse {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1%;
}

.flex_tiles_wrap_rish{
    display: flex;
    flex-wrap: wrap;
}

@media screen and  (max-width: 768px){
    .flex_wrap_rish {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .flex_wrap_rish_column-reverse {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
    }
}
@media screen and (min-width:2200px) {
    .oversize_wrap_rish {
        margin-left: 25rem;
        margin-right: 25rem;
        display: block;
    }
}

@media (min-width:1600px) and (max-width:2200px) {
    /* .oversize_wrap_rish {
       margin-left: 10rem;
       margin-right: 10rem;
       display: block;
    } */
}
  
.flex_child_one_rish {
    flex:1 1 30%;
    margin: 0 1%;
}

.flex_child_zero_rish {
    flex:1 1 45%;
    margin: 0 1%;
}

  
.flex_child_two_rish {
    flex:1 1 50%;
    margin: 0 1%;
}

.box_rish {
    /* background-color: green; */
    margin: 5px; 
    height: 30rem;
    /* padding: 30px; */
}

.svg_vertical_center_rish {
    position: absolute;
    top: 50%;
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
   left: 0;
   right: 0;
   margin: auto;
   text-align: center;
}
.primary_heading_rish {
    font-weight:normal;
    font-family:  "ProximaNova";
    letter-spacing: 0px;
    color: #1E1E1E;
    opacity: 1;
    font-size: 3rem;
    margin-bottom: .8rem;
}
.secondary-heading_rish {
   font-weight:normal;
   font-family:  "ProximaNova";
   letter-spacing: 0px;
   color: #1E1E1E;
   opacity: 1;
   font-size: 1.8rem;
   margin-bottom: .8rem;
}
.sub_heading_rish {
    /* font-weight:bold; */
    font-weight:normal;
   font-family:  "ProximaNova";
    letter-spacing: 0px;
    color: #9E9E9E;
    opacity: 1;
    font-size: 1.6rem;
    margin-bottom: .8rem;
    /* line-height: 1.6rem; */
 }
.input_rish {
    display: block;
    border-bottom: 1px solid grey;
    width: 100%;
    padding-left: 0px;
    padding-bottom: inherit;
    padding-right: inherit;
    padding-top: inherit;
    margin-top: .6rem;
    margin-bottom: .6rem;
    font-family: 'ProximaNova';
}
.button_rish {
    background: #01D35A 0% 0% no-repeat padding-box;
    border-radius: 4rem !important;
    width: auto !important;
    opacity: 1;
    padding: .8rem 2rem;
    border: none;
    font-weight:normal;
    font-family:  "ProximaNova";
    font-size: 1.2rem;
}
.button_rish:focus{
 outline: none; 
}
.color_white_rish {
    color:#fff;
}
.green_text_rish{
    color: #01D35A;;
  }
.margin_top_medium_rish {
    margin-top: 4rem;
}
.margin_top_small_rish {
    margin-top: 2rem;
}
.margin_bottom_medium_rish {
    margin-bottom: 4rem;
}
.margin_bottom_small_rish {
    margin-bottom: 2rem;
}
.margin_left_small_rish {
    margin-left: 2rem;
}
.margin_right_small_rish {
    margin-right: 2rem;
}
.info_text_rish {
  font-family: 'ProximaNova';
  color: #4E4E4E;
}

.vertical_align_rish_new {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}  

.link-text:hover {
   cursor: pointer;
   color:#01D35A !important;
}

.link_text {
cursor: pointer;
color:#01D35A !important;
}

.heading_footer_rish {
text-align: left;
font-weight:normal;
font-family:  "ProximaNova";
font-size: 1rem;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 0.7;
text-transform: uppercase;
}

.sub_heading_footer_rish {
font-weight:normal;
font-family:  "ProximaNova";
font-size: .8rem;
letter-spacing: 0.5px;
color: #FFFFFF;
opacity: 0.7;
display: block;
margin:.3rem 0rem .3rem 0rem;
text-transform: uppercase;
}
.sub_heading_footer_rish:hover {
    color: #ffff !important;
}

.footer_wrap_rish {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    flex-wrap: wrap;
    padding: 0 1%;
}

.footer_wrap_child_rish {
flex:1 1 12%;
margin: 0 1%;
}

.banner_wrapper_rish {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2%;
}
.banner_wrapper_child_rish_content {
    flex:1 1 65%;
    /* margin: 0 1%; */
}
.banner_wrapper_child_rish_button {
    flex:1 1 25%;
    /* display: flex; */
    /* margin: 0 1%; */
}

/* to remove the by default padding of navbar */
.navbar {
    padding: 0rem !important;
}
.banner-outer-wrapper {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 0;
    background: #53607B;
    margin-top: 2.9rem;
}

.banner-outer-wrapper p {
    /* margin: 0; */
    padding: 0;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
}

@media (max-width: 767px) and (min-width: 281px) {
    .banner-outer-wrapper p {
        /* padding-bottom: 0.6rem; */
        font-size: 0.7rem;
    }
    .bk_now {
        /* padding: 5px 11px 5px 10px; */
        padding: 0rem;
        font-size: 12px;
    }
    .banner_wrapper_child_rish_button {
        display: flex;
    }
}

.bk_now{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 27px;
    color: #53607B;
    padding: 0.3rem 0.3rem;
    display: block;
    font-weight: 400;
    font-size: 0.9rem;
    max-width: 10rem;
 }




 @media screen and (min-width: 767px) {
    .banner-outer-wrapper {
        margin-top: 3.5rem;
    }
 }

 @media screen and (min-width: 980px) {
    .banner-outer-wrapper {
        margin-top: 4rem;
    }
 }

 .grey_color {
   color: #9E9E9E !important;
 }

 .green_div_rish {
  background-color: green;
  height: 100%;
  width: 100%;
 }
 .blue_div_rish {
  background-color: blue;
  height: 100%;
  width: 100%;
 }

 .home_page_ul_rish .home_page_li_rish::before {
  content: "\2022";
  color:#0AD560;
  font-weight: bold;
  display: inline-block; 
  width: 1rem;
  font-size: 2rem;
  margin-left: -1rem;
}
.display_inline_rish {
  display: inline !important; 
}

.position_relative_rish {
  position: relative !important;
}

.height_100_rish {
  height: 100%;
}

.vertical_align_flex_rish {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/* footer */

.foter_colour_section {
    margin-top: 4rem;
    padding-top: 1rem;
    background-color: #1D3861;
    max-height: 30rem;
}

.responsive-logo {
    width: 8rem;
    object-fit: contain;
}

.header_link_text {
    color: #fff !important;
    font-size: 1.1rem;
    -webkit-appearance: inherit !important;
}
.header_li_rish {
    margin: auto 0rem auto 3rem;
}

.logo_rish {
    height: 2.5rem;
    width: auto;
    display: block;
    margin-top: .5rem;
    margin-left: 2rem;
}

/* .navbar_dashboard_rish {
        font-weight: 300;
        -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12) !important;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12) !important;
} */

.HospitalCoverImg {
    object-fit: contain !important;
}
@media screen and (max-width: 2100px) {
#section_rish {
     min-height: 30rem;
     margin-bottom: 2rem;
}
}

.main_content_rish {
    width: 95%;
    background-color: #fff !important;
    min-height: 30rem;
    position: relative;
    margin: auto;
}

.transparent_main_content_rish {
    width: 95%;
    min-height: 30rem;
    margin: auto;
    position: relative;
}

.catalogue_main_content_rish {
    width: 100%;
    min-height: 30rem;
    position: relative;
}


@media screen and (max-width:700px) {
    .transparent_main_content_rish {
        width: 100%;
    }
}

.navbar-collapse {
    margin-right: 0px;
}

.section_heading_rish {
    text-align: center;
    position: relative;
}
.insight_wrapp_rish-12 {
    margin-top: .5rem;
    margin-bottom: .5rem !important;
}
.modal_cross_icon {
    height: 1rem;
    width: 1rem;
    position: relative;
    left: 1.5rem;
    top: .5rem;
    cursor: pointer;
    
}
.notif_badge_wrapper_rish {
    position: absolute;
    right: 6rem;
    top: -10px;
}

.NotifyImg
    {
      background-color: #888888;
      width: 4rem;
      height: 4rem;
      color: white;
      font-weight: 400;
      border-radius: 50%;
      position: relative;
      display: block;
      margin: auto;
   }

.notif_wrap_parent {
   display: flex;
   flex-wrap: wrap;
}
.modal-heading_ris { 
    font-weight: 600 !important;
    /* font-size: 2rem; */
}
.modal_button {
    font-size: 1.5rem;
}

.notif_wrap_image {
    flex:1 1 20%;
    margin: 0 1%;
}
.notif_wrap_content {
    flex:1 1 75%;
    margin: 0 1%;
}

.availability_content_rish {
    /* width: 92%; */
    background-color: #fff !important;
    min-height: 30rem;
    position: relative;
}
.header_icon_rish {
 height: 2rem;
 width: auto;
 margin-right: .5rem;
 margin-bottom: .2rem;
}

.profile_avatar_span {
    height: 4.5rem;
    width: 4.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: auto;
    background: #AEAEAE;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 4.5rem;
    opacity: 1;
}

.profile_avatar_div {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

u {
    text-decoration: none !important;
}

.display_none {
    display: none !important;
}

.profile_name_icon {
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;
}

.catalogue_profile_name_wrapper {
    margin: 0rem .8rem 1rem 0.8rem;
}

.collumn_flex {
    flex-direction: column;
}

.insight_time_span {
    font-size: .9rem;
    display: block;
    /* margin-left: auto; */
    text-align: center;
    margin-top: 1rem;
    color: lightgreen;
    font-weight: bold;
}

.info_icon_rish {
    height: 1.2rem;
    width: auto;
    margin-left: .5rem;
    position: relative;
    top: .4rem;
    cursor: pointer;
}

.collumn_flex_rish {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.arrow_class {
    margin-left: .5rem;
    width: 1.5rem;
}
.div_class_1 {
    flex:1 1 60%;
}
.div_class_2 {
    flex:1 1 35%;
    align-items: flex-end;
}
.flex-end {
    align-items: flex-end;
}
.rish_1 {
    position: relative;
    min-height: 30rem;
    height: 100%;
}

.mb_1rem {
    margin-bottom: 1rem;
}
.main-body-wrapper {
    height: 100%;
}
.left_7rem {
    left: 7rem;
}

.flex_parent {
    display: flex;
    flex-wrap: wrap;
}

.flex_parent_child {
    flex: 1 1 45%;
}

.relative_heading {
    position: relative;
    top: 1rem;
}
.red_text {
    color:#c0392b !important; 
}
.fullpage_loader_icon {
    display: block;
    margin: auto;
    position: relative;
    top: 10rem;
    width: 20rem;
}

.toggle-password {
    position: relative !important;
    left: 0rem !important;
}

.update_price_wrapper {
    /* margin: 2rem;
    padding: 1rem; */
    display: flex;
    justify-content: space-evenly;
}
.flex_update_price_child {
    flex: 1 1 40%;
    margin: 1rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 8px;
    opacity: 1;
    padding: 1rem;
    cursor: pointer;
}

.flex_update_price_child_selected {
    box-shadow: 0px 3px 18px #1FE45A29 !important;
}
.update_price_icon_wrapper {
    display: flex;
    flex-direction: column;
}
.update_price_text {
    font-size: 1rem;
    font-family: 'ProximaNova';
    margin-top: 1rem;
}

input:focus {
    /* border: none !important;
    outline: none !important; */
    box-shadow: none !important;
  }
  