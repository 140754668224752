.composition {
    position: relative;
    &__photo {
        width: 55%;
        box-shadow: 0 1.5rem 4rem  rgba($color-black, .4);
        border-radius: 2px;   
        position: absolute;
        transition: all .2s;
        outline-offset: 1.5rem;;
    &--p1{
        left: 0rem;
        top: -2rem;
        z-index: 10;
    }

    &--p2{
        right: 0rem;
        top:  2rem;
        z-index: 10;
    }

    &--p3{
        left: 20%;
        top:  10rem;
        z-index: 10;
    }

    &:hover{
    //outline used in place of border, because we can use otline-offset which is not available with border
      outline: 1.5rem solid $color-primary;
      transform: scale(1.05) translateY(-.5rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
      z-index: 20;
    }
    }

    &:hover &__photo:not(:hover){
        transform: scale(.95);
    }
   
}