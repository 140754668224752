.catalogueComponent{
    margin-top : 7% !important;
    border: 1px solid #DCDCDC;
    border-radius: 10px;
    margin-left: 6rem;
}
.catalogue{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 900;
}

.serc_icn{position: absolute;
    right: 3rem;
    top:1rem;
    width: 2rem;}
    .serc_mar_pad{text-align: center;margin: 0 auto;position: relative;}
    .test_tme{position: relative;
        right: 11rem;}
        .catalogue h4{font-size: 1.8rem;margin: 2rem 3rem;}
        .gastroent_mar{margin-bottom: 3rem;margin-top: 3rem;}
    label + .MuiInput-formControl{margin-bottom: 0.5rem;}
.listOfService{
    /* padding: 2% 3%; */
}
.listOfServiceHeading{
    background:#F8F8F8;
    padding: 2%;
    font-weight: 500;
    font-size: 18px;
}
.Loader{
    top: 40%;
    left: 45%;
}
.catalogueViewMore{
    font-size: 1.5rem;
    border: none;
    background-color: white;
    color: #01D35A;
    padding: 2%;
}
img.covidCross {
    width: 1.4vw;
}
button.common-button{margin-bottom: 10px;}
.catalogueVariance{
    border: 1px solid #B2B2B2;
    border-radius: 35px;
    text-align: center;
    padding: 4px 0px;
}
.uploadCata{
    color: black;
    font-size: 1.2rem;
    margin-top: .4rem;
    font-weight: 500;
}