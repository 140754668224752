.upload_section_wrapper {
    transition: all .2s;
    backface-visibility: hidden;
    position: relative;
    animation-name: moveInLeft;
    animation-timing-function: ease;
    animation-duration: 0.3s; 
}

.some_random_address {
    font-size: 1.2rem;
    // font-weight: 100;
    font-family: light_proxima !important;
    // opacity: .8;
    color: #000000;
}

.align-items-center {
    display: flex;
    align-items: center;
}



