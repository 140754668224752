ul 
    {list-style-type: none;}

.signupHospitalForm
   {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    width: 30rem;
   }

.sideimg
   {display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    position: relative;}

.signup-wrap-new {
  max-height: 100vh;
  overflow-y: scroll;
}

.sidetimgcol 
    {background: #EBEBEB 0% 0% no-repeat padding-box;
     background: #F9F9F9 0% 0% no-repeat padding-box;
    opacity: 1;
    position: relative;z-index: -1}
.sigun_imu{width: 360px;display: block;
   margin-left: auto;
   margin-right: auto;
   margin-top: 20%;
   position: relative;}
.signImage 
    {display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 35px;
      position: relative;
      width: 450px;}

form
   {margin: 0px !important;}

.TypeOfSignup
   {width: 100% !important;}
.form-group h5{font-size: 16px;}
.form-group p{font-size: 15px;}
.signupHosbtn{font-size: 15px; background-color: #01D35A !important;}
.MuiList-padding{
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #B2B2B2;
border-radius: 8px;
opacity: 1;
   /* padding: 30px !important; */
}
.signupHosbtn 
   {background: #01D35A 0% 0% no-repeat padding-box;
    border-radius: 35px !important;
    width: 100%;
    color: white;
    font-size: 19px;
    margin-bottom: 20px;
    padding: 8px;}

.customborder 
   {
    border: none !important;
    border-radius: 0px !important; padding: 0px !important;
    border-bottom: 1px solid #B2B2B2 !important;
    color: #8A8A8A;
   opacity: 1;font-weight: 400;font-size: 14px !important;
}
   label {
      display: inline-block;
      margin-bottom: .5rem;
      font-size: 14px;
      font-weight: 400;
      color: #333333bf;
  }
  .MuiSelect-select.MuiSelect-select{font-size: 1.2rem; color: #333333bf;text-align: left;
   padding-left: 0.6rem;}
  
    
textarea 
   {resize: none !important; }

.HospitalRegProfileInfo
   {margin: 35px 0px 35px 0px; 
    font-size: 20px; 
    font-weight: 600;}

.Plzenter 
   {font-weight: 400;
    margin-bottom: 14px;
    margin-top: 25px;
    color: darkgrey;font-size: 14px;}
    .btn-success
{box-shadow: none !important;}
.btn-success {
   color: #fff;
   background-color: #01D35A !important;
   border-color: #01D35A;
}
.signUpText
   {font-size: 1.5rem;
    margin-top: 65px;
    margin-bottom: 20px;font-weight: 400;}

.buttonSignUp
   {padding-top: 10px;}

.AddDr
   {padding: 40px;}

.AddDrArea
   {max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;}

.manageAcc
   {margin-top:20px;}
   
