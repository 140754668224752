.heading-primary{
    color: $color-white;
    text-transform: uppercase;
    /* backfaace visibility is used to remove the shaky animation */
    backface-visibility: hidden;
    margin-bottom: 60px;

     &--main {
        font-size: 6rem;
        font-weight: 400;
        letter-spacing: 3.5rem;
        display: block;
        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-in;
        
        /* animation-delay: 2s;
          animation-iteration-count: 3;
        */
      }

      &--sub {
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 1.75rem;
        display: block;
        animation-name: moveInRight;
        animation-duration: 1s;
        animation-timing-function: ease-in;
    }
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right,
    rgba($color-primary-light, 0.8),
    rgba($color-primary-dark, 0.8));
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: .2rem;
    transition: all .2s;
    
    &:hover {
      transform: skewY(2deg) skewX(15deg) scale(1.1);
      scale: .5rem 1rem 2rem rgba($color-black, .2);
    }
}

.heading-tertiary {
  font-size: $default-font-size;
  font-weight: 700;
  text-transform: uppercase;
}

.paragraph {
  font-size: $default-font-size;
  &:not(:last-child){
    margin-bottom: 3rem ;
  }
}


.heading-component-heading {
  box-shadow: 0px 6px 10px #0000000F;
  opacity: 1;
  padding: .5rem 1.5rem;
  background-color: #fff;
  margin: 1rem 0rem;
}