label {
    display: -webkit-box;
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
    /* Accessible outline */
    /* Remove comment to use */
    /*
        &:focus-within {
                outline: .125em solid $primary-color;
        }
    */
  }
  label input {
    position: absolute;
    left: -9999px;
  }
  label input:checked + span {
    /* background-color: #F6F6F6; */
  }
  label input:checked + span:before {
    box-shadow: inset 0 0 0 0.350em #01D35A;
  }
  label span {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    padding: 0.275em 0.55em 0.275em 0.275em;
    border-radius: 99em;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
  }
  label span:hover {
    background-color: #F6F6F6;
  }
  label span:before {
    display: -webkit-box;
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    margin-right: 0.375em;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #01D35A;
  }