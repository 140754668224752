.Payment
   {margin-top: 7.8rem;
      border: 1px solid #dcdcdc;
      border-radius: 10px ;}
.paymnt_heding{
   /* font-size: 1.8rem; */
   margin: 1rem !important;
}
.helppage
   {margin-top: 95px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DCDCDC;
    border-radius: 10px;margin-left: 5rem;}

.helppageBody
  {
     padding: 1rem;
  }

.bookappoint 
   {background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DCDCDC;
    border-radius: 10px;
    margin-top: 25px;
    opacity: 1;}

.helptype 
   {margin: 20px 0px 20px 0px !important;
    font-size: 14px;}

.helpBtn{
   width: 100%;
   border:none;
   background: transparent;
}

.bookappointhead
   {margin-top: 20px;}

.helprow1
   {text-align: center;
    font-size: 1.8rem;
    font-weight: bolder;}

.helpinput
    {background-color: #dfdfdf2e !important;height: 60px !important;font-size: 1.3rem !important; border:none !important;}

/* ABOUT CSS */
.AboutHospital
   {margin-top: 95px; 
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DCDCDC;
    border-radius: 10px;margin-left: 5rem;}

.About
   {text-align: center;
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 30px;}

.AboutHospitalBody
   {
      padding: 1rem;
   }

.Aboutplunes
   {text-align: justify;font-size: 14px;}
   .Aboutplunes p{color: #474747;opacity: 0.7;line-height: 27px;}

.typeofsetting{font-size: 14px;color: #474747;opacity: 0.7;}
.typeofsetting a{color: #474747;}
/* NOTIFICATION CSS */

.Notification
{
    /* margin-top: 120px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DCDCDC;
    border-radius: 10px;
} 

.Notify
    {text-align: center;  
     font-weight: 400; 
     margin: 25px;font-size: 17px;}

.NotifierName
    {
    margin-bottom: 0px !important;     
    font-weight: 500;
    font-size: 15px;
    padding-top: 5px;
    color:gray;
   }
.intro_di{font-size: 14px;color: #474747;opacity: 0.6;}


     
.PaymentHR{margin-top: 1rem !important; margin-bottom: 1rem !important;}
