.u-center-text {
    text-align: center;
}
.u-margin-top-big {
    margin-top: 8rem;
}
.u-margin-top-medium {
    margin-top: 3rem;
}
.u-margin-top-small {
    margin-top: 1.5rem;
}

.u-margin-bottom-big {
    margin-bottom: 8rem;
}
.u-margin-bottom-medium {
    margin-bottom: 4rem;
}
.u-margin-bottom-small {
    margin-bottom: 2rem;
}
.margin_top_mini_rish {
    margin-top: .5rem;
}
.margin_top_medium-2_rish {
    margin-top: 2.2rem;
}

.u-margin-5-auto {
    margin-left: 5%;
    margin-right: 5%;
}
.u-align-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.u-margin-auto {
    margin-left: auto;
    margin-right: auto;
}
.u-display-flex {
    display: flex;
}
.download_margin {
    display: block;
    margin: 1rem auto;
}

.green_text {
    color: $color-primary !important;
}
.alt_thin_proxima {
    font-family:  alt_thin_proxima;
}

.regular_proxima {
    font-family:  regular_proxima;
}

section {
    margin-top: 1rem !important;
}

@media screen and (max-width:767px) {
    section {
        margin-top: 2rem !important;
    }
    .tab-content_rish .card_rish-header h5 button {
        font-size: 1rem;
        font-family: light_proxima;
    }

    .tab-content_rish .card_rish-body p {
        font-size: .9rem;
        font-family: light_proxima;
    }
}

.appointment-progress-wrapper {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

}
